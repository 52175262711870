import React, { Component } from "react";
import Connect from "../utilities/Connect";
import Spinner from "../utilities/Spinner";

class EnableRepeints extends Component {
    state={
        matricNumber:"",
        loading:false
    }
    handleChanges = (e) => {
        const input = e.target;
        const name = input.name;
        const value = input.type === "checkbox" ? input.checked : input.value;
        this.setState({ [name]: value });
      };

      submit = () => {
          this.setState({loading:true});
          Connect(`image/enablereprint?MatricNumber=${this.state.matricNumber}&portalId=${this.props.portalId}`,'GET')
          .then(res => res.json())
          .then(response => {
            if(response.status){
                this.setState({done:true,loading:false})
            }else{
                this.setState({errorMesage:"An error occured",loading:false})
            }
          })
      }

  render() {
    return (
        <div className="inner-card ">
      <div className="inner-wrapper d-flex align-items-center">
        <div className="inner-content text-center">
        <span className="text-primary">Please enter the matric number</span>
        {this.state.errorMesage ? <p className="text-danger">{this.state.errorMesage} </p>: null}
        {this.state.done ? <p className="text-success">{"Reprint has been triggered"}</p>: null}

          <div className="input-fields mt-3" style={{width:"100%"}}>
            <input
            
              name="matricNumber"
              type="text"
              placeholder="Matric No"
              className="matric-no-field"
              onChange={this.handleChanges}
            />
          <div className="button-section d-flex justify-content-center mx-auto mt-5">
                    <button onClick={this.submit} className="btn btn-inner">{this.state.loading? <Spinner size="small"/>: "Submit"}</button>
                  </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default EnableRepeints;
