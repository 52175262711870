import React, { useState } from 'react'
import LoggedInNav from "../Components/LooggedInNav";


function Export() {
    const [expanded, setExpanded] = useState(false);
    const [user, setUser] = useState({});
    const [branch, setBranch] = useState({abraka: "", asaba: "", oleh:""});

    const toggleNav = () => {
        setExpanded(!expanded)
    }
    
    const handleInputChange = (e) =>{
        const name = e.target.name;
        const value = e.target.value;
        setBranch({...branch, [name]: value});
    };

    const handleSubmit = (e) =>{
        e.preventDefault()
       
        if(branch.abraka || branch.asaba || branch.oleh){
            window.location.href = `https://uat.bts.com.ng/assetapi/api/image/dispatchList?[0].id=5&[0].BatchNo=${branch.abraka}&[1].id=6&[1].BatchNo=${branch.asaba}&[2].id=7&[2].BatchNo=${branch.oleh}`
            setBranch({abraka: "", asaba: "", oleh:""})
        };
    };
    return (
        <div className={expanded ? "page expand" : "page"}>
            <LoggedInNav toggleNav={toggleNav} user={user} />
            <div className="inner-section">
                <div className="inner-card">
                    <div className="inner-wrapper d-flex align-items-center">
                        <div className="inner-content text-center">
                            <form onSubmit={handleSubmit}>
                                <div className="input-fields mt-3">
                                    <input
                                        name="abraka"
                                        value={branch.abraka}
                                        type="text"
                                        placeholder="Enter BatchNos For Abraka"
                                        className="matric-no-field"
                                        onChange={handleInputChange}
                                    />
                                    <input
                                        name="asaba"
                                        value={branch.asaba}
                                        type="text"
                                        placeholder="Enter BatchNos For Asaba"
                                        className="matric-no-field"
                                        onChange={handleInputChange}
                                    />
                                    <input
                                        name="oleh"
                                        value={branch.oleh}
                                        type="text"
                                        placeholder="Enter BatchNos For Oleh"
                                        className="matric-no-field"
                                        onChange={handleInputChange}
                                    /> 
                                    <hr />                                   
                                    <div className="button-section d-flex justify-content-between mx-auto">
                                            <button                                      
                                            className="btn btn-inner"
                                            type="submit"                                     
                                            >
                                            Download
                                            </button>
                                                                         
                                    </div>                               
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Export
