import React, { Component } from "react";

class LooggedInNav extends Component {
  toggleNav = () => {
    this.props.toggleNav();
  };
  render() {
    return (
      <nav className="dashboard-nav d-flex">
        <div className="sidebar">
          <div className="sidebar-inner">
            <div className="hamburger" id="icons" onClick={this.toggleNav}>
              <div className="burger" />
            </div>
            <div className="sidebar-menu">
              <ul>
                <li className="active">
                  <i>
                    <img
                      src={process.env.REACT_APP_SUBFOLDER + "/img/favicon.png"}
                      alt=''
                    />
                  </i>
                  <a href="#">Home</a>
                </li>
                <li className="dropdowns droper" id="drip">
                  <i className="fa fa-star" />
                  <a href="#">Dashboard</a>
                  <ul className="submenu">
                    <li>
                      <i className="fa fa-pallet" />
                      <a href="#">others</a>
                    </li>
                    <li>
                      <i className="fa fa-user-plus" />
                      <a href="#">Users</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <i className="fa fa-bell" />
                  <a href="/">Notification</a>
                </li>
                <li>
                  <i className="fa fa-user-plus" />
                  <a href="/">Users</a>
                </li>
                <li>
                  <i className="fa fa-print" />
                  <a href="/export">Export ID Dispatch</a>
                </li>
                <li className="dropdowns droper">
                  <i className="fa fa-cog" />
                  <a href="/">Settings</a>
                  <ul className="submenu">
                    <li>
                      <i className="fa fa-bell" />
                      <a href="/">Notification</a>
                    </li>
                    <li>
                      <i className="fa fa-user-plus" />
                      <a href="/">Users</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <i className="fa fa-question-circle" />
                  <a href="/">Help</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="menus d-flex justify-content-between align-items-center">
          <div className="profile">
            <button className="btn new-btn">create an asset</button>
            <a href="/">Profile</a>
          </div>
          <div className="nav-menus d-flex justify-content-center align-items-center">
            <a href="/">Help</a>
            <a href="/">Notification</a>
            <a href="/">Message</a>
            <div className="assetlogo ml-5">
              <img
                src={process.env.REACT_APP_SUBFOLDER + "img/asset_simple.png"}
                alt = ''
              />
            </div>
            <div className="bts-icon ml-4">
              <img
                src={this.props.user.PhotoUrl ? this.props.user.PhotoUrl : process.env.REACT_APP_SUBFOLDER + "/img/avatar.png"}
                alt = ''
              />
            </div>
            <div className="settings-icon ml-5">
              <i className="fa fa-cog" />
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default LooggedInNav;
