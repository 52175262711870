import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Login from "./pages/Login";
import ConfirmEmail from "./pages/ConfirmEmail";
import EmailConfirmed from "./pages/EmailConfirmed";
import Pricing from "./Components/Home/Pricing";
import NewPlan from "./Components/NewPlan";
import InitializePayment from "./Components/InitializePayment";
import PaymentStatus from "./pages/PaymentStatus";
import ResetPassword from "./pages/ResetPassword";
import DownUp from "./pages/DownUp";
import Export from "./pages/Export";
function App() {
  return (
    <Router basename={process.env.REACT_APP_SUBFOLDER}>
      <div className="">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/register" component={Register} />
          <Route path="/login" component={Login} />
          <Route path="/confirmemail" component={ConfirmEmail} />
          <Route path="/emailconfirmed" component={EmailConfirmed} />
          <Route path="/pricing" component={Pricing} />
          <Route path="/addplan" component={NewPlan} />
          <Route
            path="/initializepayment/:userId/:planId"
            component={InitializePayment}
          />
          <Route path="/paymentstatus/:TransId" component={PaymentStatus} />
          <Route path="/reset-password/:code?" component={ResetPassword} />
          <Route path="/download" component={DownUp} />
          <Route path="/export" component={Export} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
