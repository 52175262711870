import React, { Component } from "react";
import LoggedInNav from "../Components/LooggedInNav";
import Connect, { postFile } from "../utilities/Connect";
import ImageUploading from "react-images-uploading";
import Spinner from "../utilities/Spinner";
import Modal from "react-modal";
import EnableRepeints from "../Components/EnableRepeints";


const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
class DownUp extends Component {
  state = {
    expanded: false,
    user: {},
    portalId: 5,
    count: 10,
    matricNumber: "",
    images: [],
    maxNumber: 50,
    uploading: false,
    loading: false,
    readyImages: 0,
    downloadLink: "",
    failedMatNumbers: [],
    showfailedModal: false,
    uploadingIndex: 1,
    uploadFinished: false,
    uploaded: false,
    uploadCount: 0,
    showReprintModal:false
  };

  onChange = async (imageList, addUpdateIndex, isRetry = false) => {
    let failed = [];
    this.setState({
      uploading: true,
      showfailedModal: true,
      uploadingIndex: 1,
      uploadCount: imageList.length,
    });
    var images = imageList.length;

    console.log(imageList);
    console.log(imageList.length);
    if (isRetry) {
      imageList = this.state.failedMatNumbers;
      this.setState({ uploadCount: imageList.length });
    }
    let data = await imageList.map(async (v, i) => {
      let payload = {};
      if (!isRetry) {
        payload = {
          Base64String: v.data_url,
          Name: v.file.name,
          UserId: this.state.user.Id,
          PortalId: this.state.portalId,
        };
      } else payload = v;
      let promise = await this.startUpload([payload]);
      console.log({ index: i });
      this.setState({
        uploadingIndex:
          this.state.uploadingIndex > i + 1 ? this.state.uploadingIndex : i + 1,
      });
      console.log({ promise });
      if (promise.response != "Uploaded Successfully") {
        let { data } = promise;
        failed = [...failed, data];
      }
      return failed;

      // if (response == "Uploaded Successfully") {
      //   let actualName = data.Name.replace("-", "/").split(".")[0];
      //   let responseMessage = `Upload was successful for ${actualName}`;
      //   this.setState({ message: responseMessage });
      //   this.setState({ uploading: false });
      // } else {
      //   let actualName = data.Name.replace("_", "/").split(".")[0];
      //   let responseMessage = `Upload failed for ${actualName}`;
      //   failed.push(actualName);
      //   this.setState({ message: responseMessage });
      //   this.setState({ uploading: false });
      // }
    });
    console.log("Hey");
    Promise.all(data).then((v) => {
      console.log({ v });
      console.log(failed);
      if (failed.length > 0) {
        this.setState({ failedMatNumbers: failed, uploading: false });
      } else {
        this.setState({ uploaded: true, uploading: false });
      }
    });
    // try{
    //   data.forEach(element => {
    //     this.setState({uploading:true})
    //     let imaginaryArray = [];
    //     imaginaryArray.push(element);
    //     Connect("image/upload", "POST", imaginaryArray)
    //     .then((res) => res.json())
    //     .then((response) => {
    //       if(response == "Uploaded Successfully"){
    //         let actualName = element.Name.replace("-","/").split(".")[0];
    //         let responseMessage = `Upload was successful for ${actualName}`;
    //         this.setState({ message: responseMessage });
    //         this.setState({uploading:false})
    //       }else{
    //         let actualName = element.Name.replace("-","/").split(".")[0];
    //         let responseMessage = `Upload failed for ${actualName}`;
    //         failed.push(actualName);
    //         this.setState({ message: responseMessage });
    //         this.setState({uploading:false})
    //       }
    //     })
    //     .catch((error) => {
    //       console.error("Error:", error);
    //       error = JSON.stringify(error);
    //       this.setState({ errorMessage: error.Message });
    //     });
    //   })
    // }catch(error){
    //   console.log(error);
    // }finally{
    //   console.log(data);
    // console.log(failed);
    // if(failed.length > 0){
    //   this.setState({showfailedModal:true, failedMatNumbers:failed});
    // }
    // }
  };

  startUpload = async (data) => {
    try {
      // this.setState({ uploading: true });
      let imaginaryArray = data;
      let response = await Connect("image/upload", "POST", imaginaryArray);
      response = await response.json();
      return { response, data: data[0] };
      // console.log(data);
      // console.log(failed);
      // if (failed.length > 0) {
      //   this.setState({ showfailedModal: true, failedMatNumbers: failed });
      // }
    } catch (error) {
      console.log("startUpload ==>", error);
    }
  };

  toggleFailedModal = () => {
    this.setState({
      showfailedModal: !this.state.showfailedModal,
      uplloadCount: 0,
      failedMatNumbers: [],
      uploadingIndex: 1,
    });
  };

  toggleNav = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleChanges = (e) => {
    const input = e.target;
    const name = input.name;
    const value = input.type === "checkbox" ? input.checked : input.value;
    this.setState({ [name]: value });
  };

  toggleReprintModal = () =>{
    this.setState({showReprintModal : !this.state.showReprintModal})
  }

  initiateDownload = () => {
    let newWIndow = window.open("", "_blank");
    let url = "";
    this.setState({ loading: true });
    
    if(this.state.matricNumber){
      this.setState({ loading: false });
      if(this.state.portalId == 1){
        this.state.portalId = 5;
      }
      let Downloadlink = 
      process.env.REACT_APP_API_BASEURL_Test +
      `image/download?MatricNumber=${this.state.matricNumber}&portalId=${this.state.portalId}`;
      console.log(this.state.portalId);
     window.location.assign(Downloadlink);
      return;
    }
   (url = `image/downloadbulk/${this.state.portalId}/${this.state.count}`);

    Connect(url, "GET")
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        let link =
          process.env.REACT_APP_API_BASEURL_Test +
          `image/downloadzip/${response}`;
        newWIndow.location = link;
        this.setState({ downloadLink: link });
        setTimeout(() => {
          this.setState({ loading: true });
          this.fetchReadyImages();
        }, 3000);
      });
    window.open(this.state.downloadLink);
  };

  init = () => {
    var user = localStorage.getItem("user");
    if (!user) {
      user = {
        Id: 0,
        PortalId: this.state.portalId,
      };
      window.location.href = "/login";
    } else {
      console.log(JSON.parse(user));
      var User = JSON.parse(user);
      console.log(User.PortalId);
      this.setState({ user: User, portalId: User.PortalId });
    }
  };

  fetchReadyImages = () => {
    this.setState({ loading: true });
    Connect("image/count", "GET")
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        this.setState({ readyImages: response, loading: false });
      });
    this.setState({ loading: false });
  };

  componentDidMount() {
    this.init();
    this.fetchReadyImages();
    setTimeout(() => {
      console.log(this.state.portalId);
    }, 3000);
  }

  render() {
    const { expanded } = this.state;
    return (
      <div className={expanded ? "page expand" : "page"} id="sidebar">
        
        <Modal isOpen={this.state.showReprintModal}
          style={customStyles}
          onRequestClose={this.toggleReprintModal}>
          <EnableRepeints portalId={this.state.portalId}/>
        </Modal>
        <Modal
          isOpen={this.state.showfailedModal}
          onRequestClose={this.toggleFailedModal}
          style={customStyles}
        >
          {this.state.uploading ? (
            <div style={{ width: "500px", height: "400px" }}>
              <h5 style={{ marginBottom: "170px" }}> Uploading </h5>
              <div className="text-center">
                {" "}
                <Spinner />{" "}
                <h6
                  style={{ marginTop: "10px" }}
                >{`Uploading ${this.state.uploadingIndex} of ${this.state.uploadCount}`}</h6>
              </div>
            </div>
          ) : this.state.failedMatNumbers.length > 0 ? (
            <div style={{ width: "500px", minHeight: "400px" }}>
              <h5 style={{ marginBottom: "30px" }}> Upload Complete </h5>
              <div className="text-center">
                <h6>The following Images failed</h6>
              </div>
              <div className="row text-danger">
                {this.state.failedMatNumbers.map((value, i) => {
                  return (
                    <div class="col-4 mb-2">
                      <p className="mb-0">
                        <small>
                          {value.Name.replace("_", "/").split(".")[0]}
                        </small>
                      </p>
                    </div>
                  );
                })}
              </div>
              <div
                className="text-center button-section"
                style={{ marginTop: "90px" }}
              >
                <button
                  className="btn btn-inner"
                  onClick={() => this.onChange([], "", true)}
                >
                  Retry Upload
                </button>
              </div>
            </div>
          ) : this.state.uploaded ? (
            <div style={{ width: "500px", height: "400px" }}>
              <h5 style={{ marginBottom: "170px" }}> Upload Complete</h5>
              <div className="text-center">
                {" "}
                <i
                  class="fa fa-check-circle text-success"
                  style={{ fontSize: "70px" }}
                ></i>
                <h6 style={{ marginTop: "10px" }}>Done</h6>
              </div>
            </div>
          ) : null}
        </Modal>
        <LoggedInNav toggleNav={this.toggleNav} user={this.state.user} />
        <div className="inner-section">
          {this.state.loading ? (
            <div className="inner-card text-center">
              <div className="inner-wrapper d-flex align-items-center justify-content-center">
                <Spinner></Spinner>
              </div>
            </div>
          ) : (
            <div className="inner-card ">
              <div className="inner-wrapper d-flex align-items-center">
                <div className="inner-content text-center">
                  {this.state.message ? (
                    <span className="text-danger">{this.state.message}</span>
                  ) : (
                    <span>
                      There are {this.state.readyImages} images ready for
                      download
                    </span>
                  )}

                  <div className="input-fields mt-3">
                    <input
                      name="matricNumber"
                      type="text"
                      placeholder="Matric No"
                      className="matric-no-field"
                      onChange={this.handleChanges}
                    />
                    <div class="row">
                      {/* <div class="col">
                        <label for="Portal Id">Portal Id</label>
                        <input
                          name="portalId"
                          type="text"
                          placeholder="portalId"
                          className="matric-no-field"
                          defaultValue={this.state.portalId}
                          value={this.state.portalId}
                          onChange={this.handleChanges}
                        />
                      </div> */}

                      <div class="col">
                        <label for="cpb">Count per batch</label>
                        <input
                          name="count"
                          type="text"
                          placeholder="Matric No"
                          className="matric-no-field"
                          defaultValue={this.state.count}
                          onChange={this.handleChanges}
                        />
                      </div>
                    </div>
                    {/* <div className="check">
                      <input
                        name=""
                        type="checkbox"
                        id
                        onChange={this.handleChanges}
                      />
                      <span>Print blah blah blah</span>
                    </div> */}
                  </div>
                  <hr />
                  {/* <div className="force-section">
                    <input
                      name="force"
                      type="checkbox"
                      id
                      onChange={this.handleChanges}
                    />
                    <span>Force Upload</span>
                  </div> */}
                  {this.state.downloadLink ? (
                    <p>
                      If the download fails please click here:
                      <a target="_blank" href={this.state.downloadLink}>
                        Retry
                      </a>
                    </p>
                  ) : null}

                  <div className="button-section d-flex justify-content-between mx-auto">
                    <button
                      className="btn btn-inner"
                      onClick={this.initiateDownload}
                      href=""
                    >
                      Download
                    </button>
                    <ImageUploading
                      multiple
                      value={this.state.images}
                      onChange={this.onChange}
                      maxNumber={this.state.maxNumber}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) =>
                        this.state.uploading ? (
                          <Spinner></Spinner>
                        ) : (
                          <button
                            onClick={onImageUpload}
                            className="btn btn-inner"
                          >
                            Upload
                          </button>
                        )
                      }
                    </ImageUploading>
                  </div>
                  <div className="button-section d-flex justify-content-center mx-auto mt-5">
                    <button onClick={this.toggleReprintModal} className="btn btn-inner">Enable Reprint</button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default DownUp;
