export default function( Url,Method,Data=null){
    if(Data){Data = JSON.stringify(Data)}
    var ModifiedUrl = process.env.REACT_APP_API_BASEURL_Test + Url;
    var Response = fetch(ModifiedUrl,{
        method: Method,
        body:Data,
        headers:{
            'Content-Type': 'application/json'
        }
      })
    
    return Response;
}

// export function postFile(Url,Method = 'POST',Data=null){
//     var ModifiedUrl = process.env.REACT_APP_API_BASEURL_Test + Url;
//     var Response = fetch(ModifiedUrl,{
//         method: Method,
//         body:Data,
//         headers:{}
//       })
    
//     return Response;
// }